import { onMount } from "solid-js";
import { useTranslation } from "~/i18n";
import { useSession } from "~/session";

export const AIDisclaimer = () => {
  const [session] = useSession();
  const { t } = useTranslation(session.locale);

  let disclaimer!: HTMLDivElement;

  onMount(() => {
    if (!disclaimer) return;
    disclaimer.animate([{ opacity: 0 }, { opacity: 1 }], { delay: 3000, duration: 200, fill: "forwards" });
  });

  return (
    <div ref={disclaimer} class="rounded-b-lg bg-black/60 px-4 py-2 opacity-0 backdrop-blur-[28px]">
      <p class="text-sm font-bold text-white">{t("ai.disclaimer")}</p>
    </div>
  );
};
